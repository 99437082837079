<template>
  <span :class="cssClass">{{ display }}</span>
</template>
<script>
export default {
  name: 'CurrencyValue',
  props: {
    value: { type: Number },
    limit: { type: Number, default: 0 },
  },
  computed: {
    cssClass() {
      return this.value < this.limit ? 'red--text' : '';
    },
    display() {
      return this.value == undefined || this.value == null
        ? '-'
        : this.value.toFixed(2);
    },
  },
};
</script>
